/**
 * Enum values for game mechanics
 */
export enum Mechanics {
  AddCard = 'ADD_CARD',
  AOE = 'AOE',
  Armor = 'ARMOR',
  Boolean = 'BOOLEAN',
  Boon = 'BOON',
  Bulwark = 'BULWARK',
  Buff = 'BUFF',
  ChooseOne = 'CHOOSE_ONE',
  Combo = 'COMBO',
  Curse = 'CURSE',
  Damage = 'DAMAGE',
  Debuff = 'DEBUFF',
  Destroy = 'DESTROY',
  Disable = 'DISABLE',
  Discard = 'DISCARD',
  Discover = 'DISCOVER',
  DrawCard = 'DRAW_CARD',
  Durability = 'DURABILITY',
  Energy = 'ENERGY',
  EnergySlot = 'ENERGY_SLOT',
  Equip = 'EQUIP',
  Event = 'EVENT',
  GlobalSpell = 'GLOBAL_SPELL',
  Heal = 'HEAL',
  Health = 'HEALTH',
  Hidden = 'HIDDEN',
  Immune = 'IMMUNE',
  None = 'NONE',
  Mystery = 'MYSTERY',
  OnDeath = 'ON_DEATH',
  OnPlay = 'ON_PLAY',
  OnTurnEnd = 'ON_TURN_END',
  OnTurnStart = 'ON_TURN_START',
  Overload = 'OVERLOAD',
  Power = 'POWER',
  Random = 'RANDOM',
  Reset = 'RESET_VALUE',
  RestoreHp = 'RESTORE_HP',
  Resurrect = 'RESURRECT_MINION',
  Return = 'RETURN',
  SetCost = 'SET_COST',
  SetCurrentEnergy = 'SET_CURRENT_ENERGY',
  SetTotalEnergy = 'SET_TOTAL_ENERGY',
  SetValue = 'SET_VALUE',
  Silence = 'SILENCE_MECHANICS',
  SpellDamage = 'SPELL_DAMAGE',
  Summon = 'SUMMON',
  Target = 'TARGET',
  TransferOwnership = 'TRANSFER_OWNERSHIP',
  Transform = 'TRANSFORM',
}
